body {
  background-color: #f8f8f8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fc-day-today {
  background-color: #f3f3f3 !important;
}

/* .fc-timegrid .fc-timeGridDay-view .fc-view {
  overflow-x: auto;
}

.fc-timeGridDay-view > table {
  min-width: 100%;
  width: 2500px !important;
} */

/* .fc-daygrid-event-harness, .fc-timegrid-event-harness {
  max-width: 300px;
  float: left;
} */

/* .fc-datagrid-cell-frame {height: 40em !important}
.fc-timeline-lane-frame {height: 40em !important} */

.fc-timegrid-slot {
  height: 35px !important;
  border-bottom: 0 !important;
}
/* 
.fc-timegrid-col.fc-day-today {
  width: 15em;
}

.fc-col-header-cell.fc-resource {
  width: 15em;
} */

.tippy-tooltip {
  background-color: #1696c5;
  border-radius: 25px;
  color: black;
}

.tippy-box[data-theme~='calendar'] {
  padding: 10px;
  background-color: rgb(134, 181, 212);
  color: '#000000';
}
